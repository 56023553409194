import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LogUpdateService {
  constructor(updates: SwUpdate) {
    console.log('pwa log service loaded');
    updates.versionUpdates
      .pipe(
        filter((versionEvent): versionEvent is VersionReadyEvent => {
          return versionEvent?.type === 'VERSION_READY';
        }),
      )
      .subscribe((event) => {
        console.log('current version is', event.currentVersion);
        console.log('available version is', event.latestVersion);
      });
    console.log('updating to new version');
    updates.activateUpdate().then((res) => {
      document.location.reload();
    });
  }
}
