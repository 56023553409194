import { Component, inject, OnInit } from '@angular/core';
import { PreviousRouteService } from '@core/services/previous-route.service';
import { Store } from '@ngxs/store';
import { SyncSelectors } from '@store/mobile/sync/sync.selectors';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { VersionCheckService } from '@capturum/complete';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageUtil } from '@core/utils/local-storage.util';
import { InactivityService } from '@core/services/inactivity.service';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { Confirmation, ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public confirmation: Confirmation;
  public existsSyncErrors$: Observable<boolean>;

  public store = inject(Store);

  constructor(
    private themeService: ThemeService,
    private previousRouteService: PreviousRouteService,
    private translateService: TranslateService,
    private versionCheckService: VersionCheckService,
    private notificationService: NotificationService,
    private inactivityService: InactivityService,
    private confirmationService: ConfirmationService,
  ) {
    this.previousRouteService.getPreviousUrl();

    this.existsSyncErrors$ = this.store.select(SyncSelectors.existsSyncErrors);
  }

  public ngOnInit(): void {
    this.fetchApplicationUpdates();
    this.inactivityService.logUserActivity();
    this.setTheme();

    this.confirmationService.requireConfirmation$.subscribe((result) => {
      return (this.confirmation = result);
    });
  }

  public setTheme(): void {
    this.themeService.setProps({
      [CSSVarNames.Primary]: '#633189',
      [CSSVarNames.Success]: '#4cd964',
      [CSSVarNames.Warning]: '#fbc11e',
      [CSSVarNames.Error]: '#e31d3a',
      [CSSVarNames.Confirm]: '#e31d3a',
      [CSSVarNames.Font]: 'Source Sans Pro',
      [CSSVarNames.Text4]: '#fff',
      [CSSVarNames.Third]: '#ddd',
      [CSSVarNames.Text]: '#212529',
      [CSSVarNames.SuccessShadow]: 'rgba(76, 217, 100, 0.25)',
      [CSSVarNames.InputHeight]: '36px',
      [CSSVarNames.DynamicFilterInputHeight]: '36px',
      [CSSVarNames.DynamicFilterBorderRadius]: '20px',
      [CSSVarNames.DynamicFilterLabelFontSize]: '14px',
      [CSSVarNames.DynamicFilterLabelFontWeight]: '600',
      [CSSVarNames.DynamicFilterPlaceholderFontColor]: '#5f6062',
      [CSSVarNames.DynamicFilterIconOpacity]: '0.5',
    });
  }

  private fetchApplicationUpdates(): void {
    if (environment.production) {
      const frequency = 60 * 1000; // 1 minute

      this.versionCheckService.initVersionCheck(`${environment.url}/version.json`, frequency);

      this.versionCheckService.onUpdateVersion.subscribe(() => {
        LocalStorageUtil.clearTableStates();

        const versionCta = {
          label: this.translateService.stream('dxp.core.version-update.cta'),
          icon: 'fas fa-sync-alt',
          callback: () => {
            return location.reload();
          },
        };

        this.notificationService.version(
          this.translateService.instant('dxp.core.version-update.title'),
          this.translateService.instant('dxp.core.version-update.description'),
          null,
          999999,
          versionCta,
        );
      });
    }
  }
}
