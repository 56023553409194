import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PromptUpdateService {
  constructor(updates: SwUpdate) {
    updates.versionUpdates
      .pipe(
        filter((event): event is VersionReadyEvent => {
          return event?.type === 'VERSION_READY';
        }),
      )
      .subscribe(() => {
        updates.activateUpdate().then(() => {
          caches.keys().then((names) => {
            for (const name of names) {
              console.log(`Deleting cached files: ${name}`);
              caches.delete(name);
            }
          });
          document.location.reload();
        });
      });
  }
}
