import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromptUpdateService } from './prompt-update.service';
import { LogUpdateService } from './log-update-service';
import { CheckForUpdateService } from './check-for-update.service';
import { InitPwaService } from '@core/pwa/init-pwa-service';

import { HandleUnrecoverableStateService } from './handle-unrecoverable-state.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class PwaModule {
  public static forRoot(): ModuleWithProviders<PwaModule> {
    return {
      ngModule: PwaModule,
      providers: [
        PromptUpdateService,
        LogUpdateService,
        CheckForUpdateService,
        InitPwaService,
        HandleUnrecoverableStateService,
      ],
    };
  }
}
