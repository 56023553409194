import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
  constructor(updates: SwUpdate) {
    console.log('Start to check every 30 seconds');
    const timeInterval$ = interval(30 * 1000);

    timeInterval$.subscribe(() => {
      console.log('Checking....');
      updates.checkForUpdate();
    });
  }
}
