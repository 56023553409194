import { Injectable } from '@angular/core';
import { CheckForUpdateService } from '@core/pwa/check-for-update.service';
import { LogUpdateService } from '@core/pwa/log-update-service';
import { PromptUpdateService } from '@core/pwa/prompt-update.service';
import { HandleUnrecoverableStateService } from '@core/pwa/handle-unrecoverable-state.service';

@Injectable({ providedIn: 'root' })
export class InitPwaService {
  constructor(
    private checkForUpdate: CheckForUpdateService,
    private logUpdate: LogUpdateService,
    private promptUpdate: PromptUpdateService,
    private handleUnrecoverableStateService: HandleUnrecoverableStateService,
  ) {
    this.handleUnrecoverableStateService.cleanUp();
  }
}
